<template>
    <v-container id="main">
        <h1>{{payment.description}}</h1>
        <h1>Amount: ${{amount}}</h1>
        <h3>Visa and Mastercard Payments Only!</h3>
        <v-divider></v-divider>
        <v-card class="mx-auto" max-width="100%">
            <v-card-text class="text--primary">
                <v-form ref="Payment" lazy-validation>
                    <v-row no-gutter class="flex-item" dense>
                        <v-col cols="12">
                            <v-text-field
                            outlined
                            dense
                            v-model="payment.card.name"
                            label="Name on Card"
                            :rules="[(v) => !!v || 'Cardholder Name is required']"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                            outlined
                            dense
                            v-model="number"
                            label="Card Number"
                            v-mask="'#### #### #### ####'"
                            :rules="[rules.required, rules.cardnumber]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field
                            outlined
                            dense
                            v-model="expiry"
                            label="Expiry"
                            v-mask="'## / ##'"
                            :rules="[rules.required, rules.expiry]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field
                            outlined
                            dense
                            v-model="cvc"
                            label="CVC"
                            v-mask="'###'"
                            :rules="[rules.required, rules.cvc]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                outlined
                                dense
                                v-model="payment.email"
                                label="Email Address"
                                :rules="[rules.required, rules.email]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                            outlined
                            dense
                            v-model="payment.card.address_line1"
                            label="Address"
                            :rules="[(v) => !!v || 'Address is required']"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                            outlined
                            dense
                            v-model="payment.card.address_city"
                            label="City"
                            :rules="[(v) => !!v || 'City is required']"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field
                            outlined
                            dense
                            v-model="payment.card.address_state"
                            label="State"
                            v-mask="'AAA'"
                            :rules="[(v) => !!v || 'State is required']"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field
                            outlined
                            dense
                            v-model="payment.card.address_postcode"
                            label="Postcode"
                            v-mask="'####'"
                            :rules="[(v) => !!v || 'Postcode is required']"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-select
                            outlined
                            dense
                            v-model="payment.card.address_country"
                            :items="Countries"
                            label="Country"
                            :rules="[(v) => !!v || 'Country is required']"
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn :disabled="processingpayment" block dark color="#830027" @click="processpayment"
          >Process Payment</v-btn>
            </v-card-actions>
        </v-card>
        <v-row class="Cancel">
            <v-spacer></v-spacer>
            <v-btn rounded block x-large color="#EDBC7B" to="/">Cancel</v-btn>
            <v-spacer></v-spacer>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      ticket: null,
      charge_token: null,
      error: false,
      expiry: "",
      number: "",
      cvc: "",
      amount: null,
      processingpayment: false,
      payment: {
        amount: null,
        description: null,
        email: null,
        card: {
            number: "",
            expiry_month: null,
            expiry_year: null,
            cvc: "",
            name: null,
            address_line1: null,
            address_city: null,
            address_postcode: null,
            address_state: null,
            address_country: null
        },
      },
      rules: {
        required: (value) => !!value || "Details Required.",
        cardnumber: (value) => value.length == 19 || "Must be 16 numbers",
        cvc: (value) => value.length == 3 || "Must be 3 numbers",
        expiry: (value) => value.length == 7 || "Must Enter MM / YY numbers",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      Countries: ["AU - Australia", "NZ - New Zealand", "UK - United Kingdom", "US - United States" ],
    };
  },
  created() {
    this.ticket = JSON.parse(localStorage.getItem("Tickets"));
    this.payment.description = this.ticket.Description;
    this.amount = this.ticket.Total;
    this.payment.card.name = `${this.ticket.Firstname} ${this.ticket.Surname}`;
    this.payment.email = `${this.ticket.Email}`;

  },
  methods: {
    processpayment() {
        if (this.$refs.Payment.validate()) {
            this.payment.card.expiry_month = this.expiry.substr(0,2)
            const myArr = this.expiry.split(" / ");
            this.payment.card.expiry_year = Number('20' + myArr[1])
            this.payment.amount = this.amount*100
            this.payment.card.number = Number(this.number.replace(/ /g, ""))
            this.payment.card.cvc = this.cvc
            this.payment.card.address_postcode = Number(this.payment.card.address_postcode)
            this.payment.card.address_country = this.payment.card.address_country.substr(0,2)
            this.processPinPayment(this.payment);
        }
    },
    processPinPayment(payment) {
        this.processingpayment = true ;
        this.$toast.info(`Payment being Processed - Please Wait!`, {
                    timeout: 2000,
                });
        //const baseURL = "https://server.ownurgoal.net.au/pin/Test_Payment";
        const baseURL = "https://server.ownurgoal.net.au/pin/Payment";
        // Process PIN Payment
        axios
            .post(baseURL, payment)
            .then((response) => {
                this.$toast.success(`Payment Successfull!`, {
                    timeout: 1000,
                });
                this.CreateTickets(response.data.payload);
            })
            .catch((error) => {
                if (error) {
                    let err_message = "" ;
                    switch (error.response.data.data.error) {
                        case "card_declined":
                        case "insufficient_funds":
                        case "invalid_cvv":
                        case "invalid_card":
                        case "processing_error":
                        case "suspected_fraud":
                        case "gateway_error":
                        case "unknown":
                            err_message = error.response.data.data.error_description ;
                            break;
                        case "invalid_resource":
                            switch (error.response.data.data.messages[0].code) {
                                case "card_expiry_month_invalid":
                                    err_message = "Card's expiry is in the past" ;
                                    break;
                                case "card_cvc_invalid":
                                    err_message = error.response.data.data.messages[0].message ;
                                    break;
                                default:
                                    err_message = "Unknown Error with this Card" ;
                                    break;
                            }
                            break;
                        default:
                            err_message = "Unknown Error with this Card"
                            break;
                    }
                    this.$toast.error(`Payment Failed: ${err_message}`, {
                        timeout: 6000,
                    });
                }
                this.processingpayment = false ;
            });
    },
    CreateTickets(payment) {
        let payload = {}
        payload.Survey = this.ticket.Description
        payload.Survey_id = this.ticket.id 
        payload.Payment_id = payment.token
        delete this.ticket.id 
        this.ticket.charge_token = payment.token
        localStorage.setItem("Tickets", JSON.stringify(this.ticket));
        payload.Survey_Data = this.ticket

        const baseURL = "https://server.ownurgoal.net.au/gen/AddSurvey";
                // Add Tickets to OUG DB
                axios
                    .post(baseURL, payload)
                    .then((response) => {
                        console.log(response.data)
                        this.$router.push(`/PaySuccess`);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
        this.processingpayment = false ;
        
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.Cancel {
    margin: 25px;
}

.flex-item {
  padding: 0px;
  margin: 1px;
  background-color: whitesmoke;
//   border: 1px solid #962283;
}

h1, h2 {
    //background-color: black;
    // border-width: 1px;
    // border-style: solid;
    text-align: center;
    // margin-bottom: 5px;
    // margin-top: 15px;
    color: #830027;
}

h3 {
    //background-color: #830027;
    //background-color: black;
    text-align: center;
    margin-bottom: 5px;
    // margin-top: 15px;
    color: #830027;
    //color: white;
}

.header {
     background-color: grey;
}
</style>